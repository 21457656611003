import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addLaunchpad } from "../../actions/LaunchPadAction";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, InputGroup, FormControl } from 'react-bootstrap'
import { toast } from 'react-toastify';
import $, { event } from 'jquery';
import  Web3 from 'web3';
import {Launchpad,LaunchpadABI,LockedStaking } from '../partials/Config';

import { CONTRACT_ABI, CONTRACT_ADDRESS } from "../config/presale";


import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class LaunchPadAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pid:0,
            PriceToken:0,
            PriceTokencrypto:0,
            TokenAddress:"",
            TokenName:"",
            TokenSymbol:"",
            TokenDecimal:0,
            BNBMinimumContribution:0,
            BNBMaximumContribution:0,
            BUSDMinimumContribution:0,
            BUSDMaximumContribution:0,
            HardCap:0,
            BUSDHardCap:0,
            PresaleAddress:"",
            //WhiteList:[{ user: "", bnbvalue: "" }],
            web3state : " ",
            user:"",
            isWhitelisted: false,
            // isbnb:true,
            iscrpto:true,
            errors: {},
            token:"",
         
            
};
    }
    componentDidMount(props){
        this.MetaMask()
        
    }
    componentWillReceiveProps(nextProps) {
        console.log("next Props>>>>",nextProps)
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-launchpad-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
        }
    }
    MetaMask = async (e) => {
        try{
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                this.setState({ web3state: web3 })
                try {
                    window.ethereum.enable().then(async () => {
                        // User has allowed account access to DApp...
                        const accounts = await web3.eth.getAccounts();
                        console.log("Account : ", accounts[0]);
                        const data = accounts[0];
                        this.setState({ user: data });
                      
                        
                    });
                } catch (e) {
                    // User has denied account access to DApp...
                }
            }
            // Legacy DApp Browsers
            else if (window.web3) {
                const web3 = new Web3(window.web3.currentProvider);
                this.setState({ web3state: web3 })
            }
            // Non-DApp Browsers
            else {
                //alert('No Dapp Supported Wallet Found');
                console.log("No Dapp Supported Wallet Found")
            }
        }catch(e){

        }
       
    }

    addlaunchpadData= async (e) => {
        

    
      
    var LaunchpadContract = await new this.state.web3state.eth.Contract(LaunchpadABI, Launchpad);
      
      var Token_Address = this.state.TokenAddress.toString();
      var Price_Token = this.state.PriceToken;
      var Price_Tokencrypto = this.state.PriceTokencrypto;
      var BNBMinimum_Contribution=((this.state.BNBMinimumContribution)* 10 ** 18).toString();
      var BNBMaximum_Contribution=((this.state.BNBMaximumContribution) * 10 ** 18).toString();
      var BUSDMinimum_Contribution=((this.state.BUSDMinimumContribution)* 10 ** 18).toString();
      var BUSDMaximum_Contribution=((this.state.BUSDMaximumContribution) * 10 ** 18).toString();
      var Hard_Cap= ((this.state.HardCap)* 10 ** 18).toString();
      var BUSDHard_Cap= ((this.state.BUSDHardCap)* 10 ** 18).toString();
      var Pid = this.state.pid;
      var Staker = LockedStaking;
     
           
          this.setState({TokenAddress: Token_Address});
          this.setState({PriceToken: Price_Token});
          this.setState({PriceTokencrypto:Price_Tokencrypto})
          this.setState({BNBMinimumContribution: BNBMinimum_Contribution,BNBMaximumContribution: BNBMaximum_Contribution});
          this.setState({BUSDMinimumContribution: BUSDMinimum_Contribution,BUSDMaximumContribution: BUSDMaximum_Contribution});
          this.setState({HardCap: Hard_Cap,BUSDHardCap:BUSDHard_Cap});
          this.setState({pid:Pid})
      
         await LaunchpadContract.methods.createPresale(this.state.TokenAddress,Price_Token,Price_Tokencrypto,BNBMaximum_Contribution,BNBMinimum_Contribution,BUSDMaximum_Contribution,BUSDMinimum_Contribution,Hard_Cap,BUSDHard_Cap,Pid,Staker).send({ from: this.state.user }).then(async(result) => {
           
            //  console.log("demo>>>",result);
            var Presale_Address = await LaunchpadContract.methods.getPresale(this.state.TokenAddress).call();
            
                e.preventDefault();
                const newLaunchpad = {
                    pid:this.state.pid,
                    PriceToken: this.state.PriceToken,
                    PriceTokencrypto:this.state.PriceTokencrypto,
                    TokenAddress:this.state.TokenAddress,
                    TokenName :this.state.TokenName,
                    TokenSymbol:this.state.TokenSymbol,
                    TokenDecimal: this.state.TokenDecimal,
                    BNBMinimumContribution: this.state.BNBMinimumContribution,
                    BNBMaximumContribution:this.state.BNBMaximumContribution,
                    BUSDMinimumContribution: this.state.BUSDMinimumContribution,
                    BUSDMaximumContribution:this.state.BUSDMaximumContribution,
                    HardCap: this.state.HardCap,
                    BUSDHardCap: this.state.BUSDHardCap,
                    PresaleAddress: Presale_Address,
                    };
                    console.log("PresaleAddress",Presale_Address);
                await this.props.addLaunchpad(newLaunchpad, this.props.history);
               // $('#add-pool-modal').modal('hide');
                
            
        }).catch(e=>{
               //try again
               toast.error( "Try Again" , toasterOption);
            //    alert("Try Again !")
            //  document.getElementById('logo_overlay').style.opacity='1';
            //  document.getElementById('loader_div').style.display='none';
        })        
  }
    onChange = async(e) => {
        console.log("e>>>>",typeof e.target.id)
        this.setState({ [e.target.id]: e.target.value });

         const ERC20_ABI = [
            {
                "inputs": [],
                "stateMutability": "nonpayable",
                "type": "constructor"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Approval",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "minTokensBeforeSwap",
                        "type": "uint256"
                    }
                ],
                "name": "MinTokensBeforeSwapUpdated",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "previousOwner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "OwnershipTransferred",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "tokensSwapped",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "ethReceived",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "tokensIntoLiqudity",
                        "type": "uint256"
                    }
                ],
                "name": "SwapAndLiquify",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "bool",
                        "name": "enabled",
                        "type": "bool"
                    }
                ],
                "name": "SwapAndLiquifyEnabledUpdated",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "value",
                        "type": "uint256"
                    }
                ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "inputs": [],
                "name": "_liquidityFee",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "_maxTxAmount",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "_taxFee",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    }
                ],
                "name": "allowance",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "decimals",
                "outputs": [
                    {
                        "internalType": "uint8",
                        "name": "",
                        "type": "uint8"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "subtractedValue",
                        "type": "uint256"
                    }
                ],
                "name": "decreaseAllowance",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tAmount",
                        "type": "uint256"
                    }
                ],
                "name": "deliver",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "excludeFromFee",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "excludeFromReward",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "geUnlockTime",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "includeInFee",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "includeInReward",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "addedValue",
                        "type": "uint256"
                    }
                ],
                "name": "increaseAllowance",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "isExcludedFromFee",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "isExcludedFromReward",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "time",
                        "type": "uint256"
                    }
                ],
                "name": "lock",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "name",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "owner",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "deductTransferFee",
                        "type": "bool"
                    }
                ],
                "name": "reflectionFromToken",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "renounceOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "liquidityFee",
                        "type": "uint256"
                    }
                ],
                "name": "setLiquidityFeePercent",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "maxTxPercent",
                        "type": "uint256"
                    }
                ],
                "name": "setMaxTxPercent",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "bool",
                        "name": "_enabled",
                        "type": "bool"
                    }
                ],
                "name": "setSwapAndLiquifyEnabled",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "taxFee",
                        "type": "uint256"
                    }
                ],
                "name": "setTaxFeePercent",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "swapAndLiquifyEnabled",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "symbol",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "rAmount",
                        "type": "uint256"
                    }
                ],
                "name": "tokenFromReflection",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "totalFees",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "totalSupply",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transferFrom",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "transferOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "uniswapV2Pair",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "uniswapV2Router",
                "outputs": [
                    {
                        "internalType": "contract IUniswapV2Router02",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "unlock",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "stateMutability": "payable",
                "type": "receive"
            }
        ];
        if(e.target.id==="TokenAddress"){
            console.log("e.target.id",e.target.id);
        var tokenAddress = e.target.value;
        console.log("tokenAddress",tokenAddress);
     
        const Token = new this.state.web3state.eth.Contract(ERC20_ABI,tokenAddress)
        this.setState({ token: Token });
        const tokenName = await Token.methods.name().call();
        this.setState({ TokenName: tokenName });
        console.log("tokenname setstate", this.state.TokenName);

        const tokenSymbol = await Token.methods.symbol().call()
        this.setState({ TokenSymbol: tokenSymbol });
        console.log("token symbol ", this.state.TokenSymbol);

        const Decimal = await Token.methods.decimals().call();
        console.log("Decimal",Decimal);
        this.setState({ TokenDecimal: Decimal })
      
        
        }
    };
   
    onFileChange = event => {
     var name = event.target.value;
        // Update the state
        this.setState({ file: event.target.files[0]});
        this.setState({ imagepath: name});
      console.log(name);
      };

      onchangeCheck=()=>{
          if(this.state.iscrpto===true){
              this.setState({
                  iscrpto:false
              })
          }
          else{
            this.setState({
                iscrpto:true
            })
          }
      }
    render() {
        const { errors } = this.state;
        const { value, show, show1 } = this.state
        return (
            <div>
                <div className="modal fade" id="add-launchpad-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add LaunchPad</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="add-launchpad">
                                <div className="row mt-2">
                                        {/* <div className="col-md-3">
                                            <label htmlFor="HardCap">Buy By BUSD</label>
                                        </div>
                                        <div className="col-md-9">
                                        <label className="switch mb-0">
                                                  
                                            <input type="checkbox" checked={this.state.iscrpto}
                                            onChange={()=>this.onchangeCheck()}                      
                                            />
                                        </label>
                                        </div> */}
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                // placeholder="Enter Deposit Fee"
                                                value={this.state.PresaleAddress}
                                                error={errors.PresaleAddress}
                                                id="PresaleAddress"
                                                type="hidden"
                                                className={classnames("form-control", {
                                                    invalid: errors.PresaleAddress
                                                })}
                                            />
                                            <span className="text-danger">{errors.PresaleAddress}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                    
                                    <div className="col-md-3">
                                        <label htmlFor="alloc">Pid</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.pid}
                                            placeholder="Enter Pid"
                                            id="pid"
                                            type="text"
                                            error={errors.pid}
                                            className={classnames("form-control", {
                                                invalid: errors.pid
                                            })}/>
                                        <span className="text-danger">{errors.pid}</span>
                                </div>
                                
                            
                                </div>
                                <div className="row mt-2">
                                    
                                    <div className="col-md-3">
                                        <label htmlFor="alloc">Price Token in BNB</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.PriceToken}
                                            placeholder="Enter Price Of Token in BNB"
                                            id="PriceToken"
                                            type="text"
                                            error={errors.PriceToken}
                                            className={classnames("form-control", {
                                                invalid: errors.PriceToken
                                            })}/>
                                        <span className="text-danger">{errors.PriceToken}</span>
                                </div>
                                
                            
                                </div>
                                <div className="row mt-2">
                                    
                                    <div className="col-md-3">
                                        <label htmlFor="alloc">Price Token in BUSD</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.PriceTokencrypto}
                                            placeholder="Enter Price Of Token in BUSD"
                                            id="PriceTokencrypto"
                                            type="text"
                                            error={errors.PriceTokencrypto}
                                            className={classnames("form-control", {
                                                invalid: errors.PriceTokencrypto
                                            })}/>
                                        <span className="text-danger">{errors.PriceTokencrypto}</span>
                                </div>
                                
                               
                                </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="TokenAddress">Token Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Token Address"
                                                value={this.state.TokenAddress}
                                                id="TokenAddress"
                                                type="text"
                                                error={errors.TokenAdresses}
                                                className={classnames("form-control", {
                                                    invalid: errors.TokenAddress
                                                })}/>
                                                  <input
                                                onChange={this.onChange}
                                                
                                                value={this.state.TokenName}
                                                id="TokenName"
                                                type="hidden"
                                                error={errors.TokenName}
                                                className={classnames("form-control", {
                                                    invalid: errors.TokenName
                                                })}/>
                                                  <input
                                                onChange={this.onChange}
                                               
                                                value={this.state.TokenSymbol}
                                                id="TokenSymbol"
                                                type="hidden"
                                                error={errors.TokenSymbol}
                                                className={classnames("form-control", {
                                                    invalid: errors.TokenSymbol
                                                })}/>
                                                  <input
                                                onChange={this.onChange}
                                                
                                                value={this.state.TokenDecimal}
                                                id="TokenDecimal"
                                                type="hidden"
                                                error={errors.TokenDecimal}
                                                className={classnames("form-control", {
                                                    invalid: errors.TokenDecimal
                                                })}/>
                                                 {/* <input
                                                onChange={this.onChange}
                                                
                                                value={this.state.pid}
                                                id="pid"
                                                type="hidden"
                                                error={errors.pid}
                                                className={classnames("form-control", {
                                                    invalid: errors.pid
                                                })}/> */}
                                            <span className="text-danger">{errors.TokenAddress}</span>
                                        </div>
                                    </div>
                                   
                                    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="BNBMinimumContribution">Minimum Contribution for BNB</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Minimum Contribution for BNB"
                                                value={this.state.BNBMinimumContribution}
                                                error={errors.BNBMinimumContribution}
                                                id="BNBMinimumContribution"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.BNBMinimumContribution
                                                })} 
                                            />
                                            <span className="text-danger">{errors.BNBMinimumContribution}</span>
                                        </div>
                                        </div>
                                        
                                        <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="BNBMaximumContribution">Maximum Contribution for BNB</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Maximum Contribution for BNB"
                                                value={this.state.BNBMaximumContribution}
                                                error={errors.BNBMaximumContribution}
                                                id="BNBMaximumContribution"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.BNBMaximumContribution
                                                })} 
                                            />
                                            <span className="text-danger">{errors.BNBMaximumContribution}</span>
                                        </div>
                                       
                                    </div>
                                    

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="BUSDMinimumContribution">Minimum Contribution for BUSD</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Minimum Contribution for BUSD"
                                                value={this.state.BUSDMinimumContribution}
                                                error={errors.BUSDMinimumContribution}
                                                id="BUSDMinimumContribution"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.BUSDMinimumContribution
                                                })} 
                                            />
                                            <span className="text-danger">{errors.BUSDMinimumContribution}</span>
                                        </div>
                                        </div>
                                        
                                        <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="BUSDMaximumContribution">Maximum Contribution for BUSD</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Maximum Contribution for BUSD"
                                                value={this.state.BUSDMaximumContribution}
                                                error={errors.BUSDMaximumContribution}
                                                id="BUSDMaximumContribution"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.BUSDMaximumContribution
                                                })} 
                                            />
                                            <span className="text-danger">{errors.BUSDMaximumContribution}</span>
                                        </div>
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="HardCap">HardCap for BNB</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Hard Cap"
                                                value={this.state.HardCap}
                                                error={errors.HardCap}
                                                id="HardCap"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.HardCap
                                                })}
                                            />
                                            <span className="text-danger">{errors.HardCap}</span>
                                        </div>
                                     
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="BUSDHardCap">HardCap for BUSD</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Hard Cap"
                                                value={this.state.BUSDHardCap}
                                                error={errors.BUSDHardCap}
                                                id="BUSDHardCap"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.BUSDHardCap
                                                })}
                                            />
                                            <span className="text-danger">{errors.BUSDHardCap}</span>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                // placeholder="Enter Deposit Fee"
                                                value={this.state.PresaleAddress}
                                                error={errors.PresaleAddress}
                                                id="PresaleAddress"
                                                type="hidden"
                                                className={classnames("form-control", {
                                                    invalid: errors.PresaleAddress
                                                })}
                                            />
                                            <span className="text-danger">{errors.PresaleAddress}</span>
                                        </div>
                                    </div>
                                   
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="add-launchpad"
                                  
                                    className="btn btn-theme whites" onClick={this.addlaunchpadData}>
                                    Add LaunchPad
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LaunchPadAddModal.propTypes = {
    addLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addLaunchpad }
)(withRouter(LaunchPadAddModal));
