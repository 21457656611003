import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    FORM_ADD,
    FORM_UPDATE
} from "./types";

export const addForm = (param, history) => dispatch => {
    var formData = new FormData();
    formData.append('pid', 0);
    formData.append('risk', 5);
    formData.append('lpSymbol', param.tokenSymbol + '-' + param.quoteTokenSymbol + ' LP');
    formData.append('alloc', param.alloc * 100);
    formData.append('isTokenOnly', false);
    formData.append('lpAddresses', param.lpAddresses);
    formData.append('tokenSymbol', param.tokenSymbol);
    formData.append('tokenAddresses', param.tokenAddresses);
    formData.append('quoteTokenSymbol', param.quoteTokenSymbol);
    formData.append('quoteTokenAdresses', param.quoteTokenAdresses);
    formData.append('depositFee', param.depositFee);
    formData.append('file', param.file);

    //console.log("formdata>>>>>>>",formData)   
    axios
        .post(backurl + "/api/form-add", formData)
        .then(res => {
            // console.log("yyyhyu>>",res)
            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateForm = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);

    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading', param.Heading);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);

    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/about", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updateArticalStatus = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.status)) formUpdateData.append('status', param.status);

    axios
        .post(backurl + "/api/status-articals", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};




export const addnewsDes = (param, history) => dispatch => {

    var stakeData = new FormData();
    stakeData.append('Title', param.Title);
    stakeData.append('Heading', param.Heading);
    stakeData.append('Description', param.Description);
    stakeData.append('NewsLink', param.NewsLink);
    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/newsdes", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateNewsDes = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);

    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading', param.Heading);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);
    if (!isEmpty(param.NewsLink)) formUpdateData.append('NewsLink', param.NewsLink);

    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/newsdes", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};




export const addArticles = (param) => dispatch => {

    var formUpdateData = new FormData();

    console.log("param", param);
    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading', param.Heading);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);
    if (!isEmpty(param.NewsLink)) formUpdateData.append('NewsLink', param.NewsLink);
    if (!isEmpty(param.Description1)) formUpdateData.append('Description1', param.Description1);
    if (!isEmpty(param.NewsLink1)) formUpdateData.append('NewsLink1', param.NewsLink1);
    if (!isEmpty(param.Description2)) formUpdateData.append('Description2', param.Description2);
    if (!isEmpty(param.NewsLink2)) formUpdateData.append('NewsLink2', param.NewsLink2);
    if (!isEmpty(param.Description3)) formUpdateData.append('Description3', param.Description3);
    if (!isEmpty(param.NewsLink3)) formUpdateData.append('NewsLink3', param.NewsLink3);
    if (!isEmpty(param.status)) formUpdateData.append('status', param.status);

    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/add/articles", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const updateArticles = (param) => dispatch => {

    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);

    if (!isEmpty(param.Title)) formUpdateData.append('Title', param.Title);
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading', param.Heading);
    if (!isEmpty(param.Description)) formUpdateData.append('Description', param.Description);
    if (!isEmpty(param.NewsLink)) formUpdateData.append('NewsLink', param.NewsLink);
    if (!isEmpty(param.Description1)) formUpdateData.append('Description1', param.Description1);
    if (!isEmpty(param.NewsLink1)) formUpdateData.append('NewsLink1', param.NewsLink1);
    if (!isEmpty(param.Description2)) formUpdateData.append('Description2', param.Description2);
    if (!isEmpty(param.NewsLink2)) formUpdateData.append('NewsLink2', param.NewsLink2);
    if (!isEmpty(param.Description3)) formUpdateData.append('Description3', param.Description3);
    if (!isEmpty(param.NewsLink3)) formUpdateData.append('NewsLink3', param.NewsLink3);
    if (!isEmpty(param.status)) formUpdateData.append('status', param.status);

    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/articles", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};



export const addAI1 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage1", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI1 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage1", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const addAI2 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage2", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI2 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage2", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const addAI3 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage3", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI3 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage3", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const addAI4 = (param, history) => dispatch => {

    var stakeData = new FormData();

    if (!isEmpty(param.file)) {
        stakeData.append('file', param.file);

    }
    axios
        .post(backurl + "/api/add/aimage4", stakeData)
        .then(res => {

            dispatch({
                type: FORM_ADD,
                payload: res,
            })
        }
        ).catch(err => {
            // console.log("yyyhyu>>",err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

export const updateAI4 = (param) => dispatch => {


    var formUpdateData = new FormData();
    if (!isEmpty(param._id)) formUpdateData.append('_id', param._id);
    if (!isEmpty(param.file)) {
        formUpdateData.append('file', param.file);

    }

    axios
        .post(backurl + "/api/update/aimage4", formUpdateData)
        .then(res =>

            dispatch({
                type: FORM_UPDATE,
                payload: res,

            })


        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updateHomeForm = (params) => dispatch => {

    try {
        console.log("params_data", params)
        var formUpdateData = new FormData();
        if (!isEmpty(params.Identifier)) formUpdateData.append('Identifier', params?.Identifier);
        if (!isEmpty(params.Title)) formUpdateData.append('Title', params?.Title);
        if (!isEmpty(params.Description)) formUpdateData.append('Description', params?.Description);

        if (!isEmpty(params.Heading1)) formUpdateData.append('Heading1', params?.Heading1);
        if (!isEmpty(params.Heading2)) formUpdateData.append('Heading2', params?.Heading2);
        if (!isEmpty(params.Heading3)) formUpdateData.append('Heading3', params?.Heading3);
        if (!isEmpty(params.Heading4)) formUpdateData.append('Heading4', params?.Heading4);

        if (!isEmpty(params.Value1)) formUpdateData.append('Value1', params?.Value1);
        if (!isEmpty(params.Value2)) formUpdateData.append('Value2', params?.Value2);
        if (!isEmpty(params.Value3)) formUpdateData.append('Value3', params?.Value3);
        if (!isEmpty(params.Value4)) formUpdateData.append('Value4', params?.Value4);

        if (!isEmpty(params.SubTitle1)) formUpdateData.append('SubTitle1', params?.SubTitle1);
        if (!isEmpty(params.SubTitle2)) formUpdateData.append('SubTitle2', params?.SubTitle2);
        if (!isEmpty(params.SubTitle3)) formUpdateData.append('SubTitle3', params?.SubTitle3);
        if (!isEmpty(params.SubTitle4)) formUpdateData.append('SubTitle4', params?.SubTitle4);
        if (!isEmpty(params.SubTitle5)) formUpdateData.append('SubTitle5', params?.SubTitle5);
        if (!isEmpty(params.SubTitle6)) formUpdateData.append('SubTitle6', params?.SubTitle6);
        if (!isEmpty(params.SubTitle7)) formUpdateData.append('SubTitle7', params?.SubTitle7);

        if (!isEmpty(params.SubDescription1)) formUpdateData.append('SubDescription1', params?.SubDescription1);
        if (!isEmpty(params.SubDescription2)) formUpdateData.append('SubDescription2', params?.SubDescription2);
        if (!isEmpty(params.SubDescription3)) formUpdateData.append('SubDescription3', params?.SubDescription3);
        if (!isEmpty(params.SubDescription4)) formUpdateData.append('SubDescription4', params?.SubDescription4);
        if (!isEmpty(params.SubDescription5)) formUpdateData.append('SubDescription5', params?.SubDescription5);
        if (!isEmpty(params.SubDescription6)) formUpdateData.append('SubDescription6', params?.SubDescription6);
        if (!isEmpty(params.SubDescription7)) formUpdateData.append('SubDescription7', params?.SubDescription7);

        if (!isEmpty(params.ImageURI1)) formUpdateData.append('ImageURI1', params?.ImageURI1);
        if (!isEmpty(params.ImageURI2)) formUpdateData.append('ImageURI2', params?.ImageURI2);
        if (!isEmpty(params.ImageURI3)) formUpdateData.append('ImageURI3', params?.ImageURI3);
        if (!isEmpty(params.ImageURI4)) formUpdateData.append('ImageURI4', params?.ImageURI4);
        if (!isEmpty(params.ImageURI5)) formUpdateData.append('ImageURI5', params?.ImageURI5);
        if (!isEmpty(params.ImageURI6)) formUpdateData.append('ImageURI6', params?.ImageURI6);
        if (!isEmpty(params.ImageURI7)) formUpdateData.append('ImageURI7', params?.ImageURI7);
        if (!isEmpty(params.ImageURI8)) formUpdateData.append('ImageURI8', params?.ImageURI8);

        if (!isEmpty(params.ImageLink1)) formUpdateData.append('ImageLink1', params?.ImageLink1);
        if (!isEmpty(params.ImageLink2)) formUpdateData.append('ImageLink2', params?.ImageLink2);
        if (!isEmpty(params.ImageLink3)) formUpdateData.append('ImageLink3', params?.ImageLink3);
        if (!isEmpty(params.ImageLink4)) formUpdateData.append('ImageLink4', params?.ImageLink4);
        if (!isEmpty(params.ImageLink5)) formUpdateData.append('ImageLink5', params?.ImageLink5);
        if (!isEmpty(params.ImageLink6)) formUpdateData.append('ImageLink6', params?.ImageLink6);
        if (!isEmpty(params.ImageLink7)) formUpdateData.append('ImageLink7', params?.ImageLink7);
        if (!isEmpty(params.ImageLink8)) formUpdateData.append('ImageLink8', params?.ImageLink8);

        axios
            .post(backurl + "/api/update/home", formUpdateData)
            .then(res => 
                dispatch({
                    type: FORM_UPDATE,
                    payload: res,
                }))
                    .catch(err =>
                        dispatch({
                            type: GET_ERRORS,
                            payload: err.response.data
                        })
                        )

    } catch (err) {
        console.log('errrrrr', err)
    }
}
