import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addnewsDes } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import  Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class NewsDesAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id:"",
            Heading:"",
            Title : "",
            Description:"",
            NewsLink:"",
            errors: {},
            file : {},
};
    }
   
    componentWillReceiveProps(nextProps) {
       
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-newsdes-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
        }
    }
   
    onChange = e => {
        
        this.setState({ [e.target.id]: e.target.value });

    };
     
    onFileChange = event => {
        var name = event.target.value;
           // Update the state
           this.setState({ file: event.target.files[0]});
           this.setState({ imagepath: name});
         //console.log(name);
         };

    handleChange = (value) => {
        this.setState({ Description:value });       
       }

    // onFileChange = async(event) => {
    //     var name = event.target.value;
    //     this.setState({ file: event.target.files[0]});
    //     this.setState({ imagepath: name});
    //        var width;
    //        var height;
    //        var img = new Image()
    //        img.src = window.URL.createObjectURL(event.target.files[0])
    //        img.onload = () => {
    //         width = img.width;
    //         height = img.height;
      
           
        
    //        if ( Number(width) != Number(1600) && Number(height) != Number(675)){
    //         toast.error("Image is Not Support",{
    //             style: {
    //             minWidth: '300px',
    //             minHeight: '55px'
    //             }
    //         }) 

    //         this.setState({ file: ""});
    //               this.setState({ imagepath: ""});
             
    //       }
          
    //         sleep(2000) 
           
    
    //        }
    //      };
    onStakeAdd = e => {
        e.preventDefault();
        const newStake = {
            Heading: this.state.Heading,
            Title:this.state.Title,
            Description:this.state.Description,
            NewsLink:this.state.NewsLink,
            file: this.state.file
            
        };
       
        this.props.addnewsDes(newStake, this.props.history);
       // $('#add-pool-modal').modal('hide');
        
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-newsdes-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add News Description</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="add-stake">
                                <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Title"
                                                value={this.state.Title}
                                                id="Title"
                                                required
                                                type="text"
                                                error={errors.Title}
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}/>
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                               
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                placeholder="Enter Heading"
                                                value={this.state.Heading}
                                                id="Heading"
                                                required
                                                type="text"
                                                error={errors.Heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}/>
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenSymbol">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                        <ReactQuill                                               
                                        
                                        onChange={this.handleChange}
                                         value={this.state.Description}
                                         error={errors.Description}
                                         id="Description"
                                         theme="snow" 
                                            type="text" 
                                        
                                     />
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenSymbol">Youtube Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Youtube Link"
                                                value={this.state.NewsLink}
                                                id="NewsLink"
                                                required
                                                type="text"
                                                error={errors.NewsLink}
                                                className={classnames("form-control", {
                                                    invalid: errors.NewsLink
                                                })}/>
                                            <span className="text-danger">{errors.NewsLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>
                                    
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="add-stake"
                                  
                                    className="btn btn-primary" onClick={this.onStakeAdd}>
                                    Add
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NewsDesAddModal.propTypes = {
    addStake: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addnewsDes }
)(withRouter(NewsDesAddModal));
